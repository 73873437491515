import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {
    Assignment, AssignmentComment,
    AuthCheckInfo,
    Document,
    BookStatus,
    FullProjectAssignment,
    ProjectFiles,
    Project,
    ProjectActivity,
    ProjecTarget,
    ProjectsQueryResponse,
    ProjectType,
    UpdateProject,
    ProjectFile,
    TimeBasedSetting, ProjectRemoteFile, ProjectDocument, TBD, TBDQueryResponse
} from './_models'
import {ICreateProject} from "../create-project/CreateProjectHelper";
import TBDs from "../../../modules/project/components/TBD";

const API_URL = process.env.REACT_APP_API_URL;
const PROJECT_TYPE_URL = `${API_URL}/project_types`;
const PROJECT_URL = `${API_URL}/projects`;
const DASHBOARD_URL = `${API_URL}/dashboard/projects_stats`;
const ASSIGNMENT_URL = `${API_URL}/assignments`;
const TBD_URL = `${API_URL}/TBDs`;
const AUTH_URL = `${API_URL}/authUser`;

const getProjectTypes = (): Promise<ProjectType[]> => {
    return new Promise((resolve, reject) => {
        axios.get(PROJECT_TYPE_URL)
            .then(({data}) => resolve(data))
            .catch(reject);
    })
}

const getProjects = (page?: number | string,status?: string): Promise<ProjectsQueryResponse> => {
    return new Promise((resolve, reject) => {
        axios.get(`${PROJECT_URL}`, {
            params: {
                page: page,
                status:status
            }
        })
            .then(({data}) => resolve(data))
            .catch((err) => reject(err))
    });
}

const getDashboardProjects = (page?: number | string,status?: string, type?: string): Promise<ProjectsQueryResponse> => {
    return new Promise((resolve, reject) => {
        axios.get(`${DASHBOARD_URL}`, {
            params: {
                page: page,
                status:status,
                type: type
            }
        })
            .then(({data}) => resolve(data))
            .catch((err) => reject(err))
    });
}

const getProjectById = (id: ID): Promise<Project | undefined> => {
    return new Promise((resolve, reject) => {
        axios.get(`${PROJECT_URL}/${id}/get`)
          .then(({data}) => resolve(data))
          .catch(reject);
    });
}

const createProject = (projectData: ICreateProject): Promise<Project | undefined> => {
    const formData = new FormData();
    for (let i in projectData) {
        if (projectData[i]) {
            if (Array.isArray(projectData[i])) {
                for (let j in projectData[i]) {
                    formData.append(`${i}[${j}]`, projectData[i][j]);
                }
            } else {
                formData.append(i, projectData[i]);
            }
        }
    }

    return axios
        .post(`${PROJECT_URL}/store`, formData)
        .then((response: AxiosResponse<Response<Project>>) => response.data)
        .then((response: Response<Project>) => response.data)
}

const updateProject = (project: UpdateProject): Promise<Project | undefined> => {
    const formData = new FormData();

    for (let i in project) {
        if (i === 'logo' && typeof project[i] === 'string') {
            continue;
        }
        if (project[i] !== null) {
            if (Array.isArray(project[i])) {
                for (let j in project[i]) {
                    formData.append(`${i}[${j}]`, project[i][j]);
                }
            } else {
                formData.append(i, project[i]);
            }
        }
    }

    return axios
        .post(`${PROJECT_URL}/${project.id}/update`, formData)
        .then((response: AxiosResponse<Response<Project>>) => response.data)
        .then((response: Response<Project>) => response.data)
}

const cancelProject = (projectId: ID): Promise<void> => {
    return axios.post(`${PROJECT_URL}/${projectId}/cancel`).then(() => {
    })
}

const getProjectActivities = async (id: ID, cursor?: string): Promise<ProjectActivity | undefined> => {
    const url = cursor
        ? `${PROJECT_URL}/${id}/activities?cursor=${encodeURIComponent(cursor)}`
        : `${PROJECT_URL}/${id}/activities`;

    const response = await axios.get(url);
    return response.data;
}

const getProjectBookStatus = async (id: ID, cursor?: string): Promise<BookStatus> => {
    const url = cursor
        ? `${PROJECT_URL}/${id}/book_status?cursor=${encodeURIComponent(cursor)}`
        : `${PROJECT_URL}/${id}/book_status`;

    const response = await axios.get(url);
    return response.data;
}

const getProjectDocuments = async (id: ID, type: 'active'|'archived', cursor?: string): Promise<Document> => {
    const response = await axios.get(`${PROJECT_URL}/${id}/documents`, {
        params: {type, cursor}
    });
    return response.data;
}

const getProjectAssignments = async (id: ID, cursor?: string): Promise<Assignment> => {
    const url = cursor
        ? `${PROJECT_URL}/${id}/assignments?cursor=${encodeURIComponent(cursor)}`
        : `${PROJECT_URL}/${id}/assignments`;

    const response = await axios.get(url);
    return response.data;
}

const getProjectAssignment = async (id: ID): Promise<FullProjectAssignment> => {
    const response = await axios.get(`${ASSIGNMENT_URL}/${id}`);
    return response.data;
}

const getProjectTargets = (id: ID): Promise<ProjecTarget[] | undefined> => {
    return axios
        .get(`${API_URL}/targets/${id}`)
        .then((response: AxiosResponse<Response<ProjecTarget[]>>) => {
            return response.data;
        })
        .then((response: Response<ProjecTarget[]>) => response.data)
}

const getProjectFiles = (project_id: ID, cursor: string): Promise<ProjectFiles | undefined> => {
    const url = cursor
        ? `${API_URL}/files/${project_id}?cursor=${encodeURIComponent(cursor)}`
        : `${API_URL}/files/${project_id}`;

    return axios.get(url).then((response) => {
        console.log("files"+JSON.stringify(response.data));
        return response.data;
    });
};

const addProjectFile = (project_id: ID, file: any): Promise<ProjectFiles | undefined> => {
    const formData = new FormData()
    formData.append('file', file)

    return axios.post(`${API_URL}/files/${project_id}/add`, formData).then((response) => {
        return response.data;
    });
};

const addRemoteFile = (project_id: ID, label: string, url: string): Promise<ProjectFile | undefined> => {
    const formData = new FormData()
    formData.append('label', label);
    formData.append('url', url);
    return axios.post(`${API_URL}/files/${project_id}/add/remote`, formData).then((response) => {
        console.log(response.data)
        return response.data;
    });
};

const removeProjectFile = (file_id: ID): Promise<ProjectFiles | undefined> => {
    return axios.post(`${API_URL}/files/remove`, {file_id}).then((response) => {
        return response.data;
    });
};

const removeProjectRemoteFile = (file_id: ID): Promise<ProjectFiles | undefined> => {
    return axios.post(`${API_URL}/files/remove/remote`, {file_id}).then((response) => {
        return response.data;
    });
};

const validateProject = async (projectId: ID): Promise<void> => {
    
    await axios.post(`${PROJECT_URL}/validate`, {
        project_id: projectId
    });
}

const payProject = async (projectId: ID): Promise<void> => {
    await axios.post(`${PROJECT_URL}/pay`, {
        project_id: projectId
    });
}

const declineProject = async (projectId: ID): Promise<void> => {
    await axios.post(`${PROJECT_URL}/decline`, {
        project_id: projectId
    });
}

const addBankFile = (document_id: ID, file: any): Promise<ProjectDocument> => {
    const formData = new FormData();
    formData.append('file', file)
    return axios.post(`${API_URL}/documents/${document_id}/add_file`, formData).then((response) => {        return response.data;
    });
};

const addBankRemoteFile = (document_id: ID, label: string, url: string): Promise<ProjectDocument> => {
    const formData = new FormData()
    formData.append('label', label);
    formData.append('url', url);
    return axios.post(`${API_URL}/documents/${document_id}/add_remote_file`, formData).then((response) => {
        return response.data;
    });
};

const addAssignmentComment = (assignment_id: ID, comment: string, file: any): Promise<AssignmentComment | undefined> => {
    const formData = new FormData()
    formData.append('comment', comment)
    if (file != null) {
        formData.append('file', file)
    }
    return axios.post(`${API_URL}/assignments/${assignment_id}/comment/store`, formData).then((response) => {
        return response.data;
    });
};

const addUser = async (projectId: string, user_email:string): Promise<string> => {
    try{
        const response =  await axios.post(`${PROJECT_URL}/${projectId}/add_user`,
        {
            project_id:projectId,
            email: user_email
        })
        return response.data;
    }
    catch(error){
        throw error;
    }
}

const removeUser = async (projectId: string, userId:string): Promise<any> => {
    try {
        const response = await axios.post(`${PROJECT_URL}/${projectId}/remove_user`, {
            project_id: projectId,
            user_id: userId
        });
        return response.data.message;
    } catch (error) {
        throw error;
    }
};

const acceptProject = async (projectId: string, token:string): Promise<any> => {
    try {
        const response = await axios.post(`${PROJECT_URL}/${projectId}/accept_project`, {
            project_id: projectId,
            token:token
        });
        return response.data.message;
    } catch (error) {
        throw error;
    }
};

const getTimeBasedSetting = async (projectId: string | number): Promise<TimeBasedSetting> => {
    const url = `${PROJECT_URL}/${projectId}/time_based_setting`;

    const response = await axios.get(url);
    return response.data;
}

const approveTimeBasedSetting = async (projectId: string | number): Promise<void> => {
    await axios.post(`${PROJECT_URL}/${projectId}/time_based_setting/approve`);
}

const getProjectTBDs = async (projectId: ID, type: 'active'|'archived', cursor?: string): Promise<TBDQueryResponse> => {
    const response = await axios.get(`${TBD_URL}/${projectId}/get`, {
        params: {type, cursor}
    });
    return response.data;
}

const saveTBDComment = (tbd_id: ID, answer: string): Promise<TBD | undefined> => {
    return axios.post(`${TBD_URL}/${tbd_id}/comment`, {answer}).then((response) => {
        return response.data;
    });
};

const AuthCheck = (): Promise<AuthCheckInfo> => {
    return axios
        .get(`${AUTH_URL}`)
        .then((d: AxiosResponse<AuthCheckInfo>) => d.data)
}


export {
    getProjectTypes,
    getProjects,
    cancelProject,
    getProjectById,
    createProject,
    updateProject,
    getProjectActivities,
    getProjectTargets,
    getProjectFiles,
    addProjectFile,
    addRemoteFile,
    addBankRemoteFile,
    removeProjectFile,
    removeProjectRemoteFile,
    validateProject,
    payProject,
    AuthCheck,
    declineProject,
    getProjectBookStatus,
    getProjectDocuments,
    addBankFile,
    getProjectAssignments,
    getProjectAssignment,
    addAssignmentComment,
    getDashboardProjects,
    addUser,
    acceptProject,
    removeUser,
    getTimeBasedSetting,
    approveTimeBasedSetting,
    getProjectTBDs,
    saveTBDComment,
}
