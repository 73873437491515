import {configureStore} from '@reduxjs/toolkit';
import AccountSlice from "./AccountSlice";
import ProjectSlice from "./ProjectSlice";
import AssignmentSlice from "./AssignmentSlice";
import FileSlice from "./FileSlice";
import DocumentSlice from "./DocumentSlice";
import TBDSlice from "./TBDSlice";

export default configureStore({
    reducer: {
        account: AccountSlice,
        project: ProjectSlice,
        assignment: AssignmentSlice,
        file: FileSlice,
        document: DocumentSlice,
        tbd: TBDSlice,
    },
});