/* eslint-disable jsx-a11y/anchor-is-valid */
import { getProjects } from "../../pages/project/core/_requests";
import { useQuery } from "react-query";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Project } from "../../pages/project/core/_models";
import moment from "moment/moment";

type ProjectItemProps = {
  project: Project;
};

type ProjectStatusProps = {
  status:
    | "pending"
    | "approved"
    | "in_progress"
    | "completed"
    | "overdue"
    | "canceled";
};

export const ProjectStatus = ({ status }: ProjectStatusProps) => {
  const capitalizedStatus = (status: string) => {
    if (status === "approved") {
      return "Approve needed";
    } else if (status === "overdue") {
        return "Payment Due";
    } else {
      return (status.charAt(0).toUpperCase() + status.slice(1)).replaceAll(
        "_",
        " "
      );
    }
  };

  const statusColor = (status: string) => {
    if (status == "pending") return "secondary";
    else if (status == "approved") return "info";
    else if (status == "in_progress") return "primary";
    else if (status == "completed") return "success";
    else if (status == "overdue") return "warning";
    else return "danger";
  };

  return (
    <span
      className={`badge badge-light-${statusColor(
        status
      )} fw-bolder me-auto px-4 py-3`}
    >
      {capitalizedStatus(status)}
    </span>
  );
};

const ProjectItem = ({ project }: ProjectItemProps) => {
  return (
    <Link
      to={`${project.id}`}
      className="card border border-2 border-gray-300 border-hover h-100"
    >
      <div className="card-header border-0 pt-9">
        <div className="card-title m-0">
          <div className="symbol">
            <div className="symbol symbol-50px w-50px bg-light">
              <img src={project.logo} alt="project_logo" />
            </div>
          </div>
        </div>

        <div className="card-toolbar">
          <ProjectStatus status={project.status} />
        </div>
      </div>

      <div className="card-body p-9 d-flex flex-column justify-content-between">
        <div>
          <div className="fs-3 fw-bolder text-dark">{project.title}</div>

          <p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">
            {project.description}
          </p>

          <div className="d-flex flex-wrap mb-5">
            {project.deadline &&
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                  <div className="fs-6 text-gray-800 fw-bolder">{moment(project.deadline).format("MMM DD, YYYY")}</div>
                  <div className="fw-bold text-gray-400">Due Date</div>
                </div>
            }
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
              <div className="fs-6 text-gray-800 fw-bolder">{project.budget_amount}</div>
              <div className="fw-bold text-gray-400">Budget Set</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
              <div className="fs-6 text-gray-800 fw-bolder">{project.used_budget}</div>
              <div className="fw-bold text-gray-400">Budget Used</div>
            </div>
          </div>

          <div className="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project completed">
            <div
                className={`bg-${
                    project.progress < 40
                        ? "danger"
                        : project.progress < 80
                            ? "warning"
                            : "success"
                } rounded h-4px`}
                role="progressbar"
                style={{width: `${project.progress}%`}}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

function Projects() {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") || "all";
  const currentPage = searchParams.get("page") || 1;
  const navigate = useNavigate();

  const {data, isLoading} = useQuery(["/projects", currentPage, status], () =>
      getProjects(currentPage, status)
  );

  const handleSelectChange = (event) => {
    const selectedStatus = event.target.value;
    searchParams.set("status", selectedStatus);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  const handlePageChange = (page) => {
    searchParams.set("page", page);
    navigate(`?${searchParams.toString()}`);
  };

  const getFilteredProjects = (projects) => {
    return projects?.filter((project) => {
      if (!(status === "all" || !status)) {
        return project.status === status;
      }
      return project;
    });
  };

  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-6">
        <h3 className="fw-bolder my-2">My Projects</h3>

        <div className="d-flex flex-wrap my-2">
          <div className="me-4">
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              className="form-select form-select-sm form-select-white w-125px"
              value={status || "all"}
              onChange={handleSelectChange}
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="in_progress">In Progress</option>
              <option value="completed">Completed</option>
              <option value="overdue">Payment Due</option>
              <option value="canceled">Canceled</option>
            </select>
          </div>
        </div>
      </div>
      <>
        {isLoading ? (
          <div className="text-center my-10" id="files-loading">
            <span className="spinner-border align-middle"></span>
          </div>
        ) : !getFilteredProjects(data?.data).length ? (
          <div></div>
        ) : (
          <>
            <div className="row g-6 g-xl-9">
              <div className="row g-6 g-xl-9">
                {data &&
                  Array.isArray(data.data) &&
                  getFilteredProjects(data.data)?.map((item, index) => {
                    return (
                      <div className="col-md-6 col-xl-4" key={index}>
                        <ProjectItem project={item} />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="d-flex flex-stack flex-wrap pt-10">
              <div className="d-flex justify-content-between flex-fill d-sm-none">
                <ul className="pagination">
                  <li className="page-item disabled">
                    <span className="page-link">
                      <i className="previous"></i>
                    </span>
                  </li>

                  <li className="page-item next">
                    <a
                      className="page-link"
                      href="https://spreadsheet-backend.definance.one/projects?page=2"
                      rel="next"
                    >
                      <i className="next"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="d-none flex-sm-fill d-sm-flex align-items-sm-center justify-content-sm-between">
                <div className="fs-6 fw-semibold text-gray-700">
                  Showing
                  <span className="fw-semibold"> {data?.meta?.from} </span>
                  to
                  <span className="fw-semibold"> {data?.meta?.to} </span>
                  of
                  <span className="fw-semibold"> {data?.meta?.total} </span>
                  results
                </div>

                <div>
                  <ul className="pagination">
                    <li
                      className={`page-item previous 
                      ${data?.meta?.current_page === 1 ? "disabled" : ""}`}
                    >
                      <button
                        type="button"
                        className={`page-item previous btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-1`}
                        disabled={data?.meta?.current_page === 1}
                        onClick={() => {
                          if (data?.meta?.current_page) {
                            handlePageChange(data?.meta?.current_page - 1);
                          }
                        }}
                      >
                        <span className="page-link">
                          <i className="previous"></i>
                        </span>
                      </button>
                    </li>
                    {Array.from({ length: data?.meta?.last_page || 0 }).map(
                      (_, index) => (
                        <li
                          className={`page-item cursor-pointer ${
                            data?.meta?.current_page === index + 1
                              ? "active"
                              : ""
                          }`}
                          aria-current="page"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          <span className="page-link">{index + 1}</span>
                        </li>
                      )
                    )}

                    <li
                      className={`page-item next ${
                        data?.meta?.current_page === data?.meta?.last_page
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <button
                        type="button"
                        className={`page-item previous btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-1`}
                        disabled={
                          data?.meta?.current_page === data?.meta?.last_page
                        }
                        onClick={() => {
                          if (data?.meta?.current_page) {
                            handlePageChange(data?.meta?.current_page + 1);
                          }
                        }}
                      >
                        <span className="page-link">
                          <i className="next"></i>
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
}
