import { createSlice } from '@reduxjs/toolkit';

export const projectSlice= createSlice({
    name: 'files',
    initialState: {
        currentFile: null,
        currentRemoteFile: null,
        files: [],
        filesTitle: null,
    },
    reducers: {
        setCurrentFile: (state, action) => {
            state.currentFile = action.payload;
        },
        setCurrentRemoteFile: (state, action) => {
            state.currentRemoteFile = action.payload;
        },
        setFiles: (state, action) => {
            state.files = action.payload;
        },
        setFilesTitle: (state, action) => {
            state.filesTitle = action.payload;
        },
    }
})

export const {
    setCurrentFile,
    setCurrentRemoteFile,
    setFilesTitle,
    setFiles,
} = projectSlice.actions;

export default projectSlice.reducer;