import { createSlice } from '@reduxjs/toolkit';

export const tbdSlice= createSlice({
    name: 'TBDs',
    initialState: {
        currentTBD: null,
    },
    reducers: {
        setCurrentTBD: (state, action) => {
            state.currentTBD = action.payload;
        },
    }
});

export const { setCurrentTBD } = tbdSlice.actions;

export default tbdSlice.reducer;
