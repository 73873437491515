import {createSlice, current} from '@reduxjs/toolkit';

export const accountSlice= createSlice({
    name: 'account',
    initialState: {
        passwordReset: false,
        addingHourFrom: 'default',//project_form|approve_project|pay_project|default
        paymentIsSuccessful: false,
        hourToAdd: 0,
        insufficientHour: false,
        payableQuantity:0,
        isNewPaymentMethod:false,
        currentPaymentMethod:null,
        settingEnableAutoPay:false,
    },
    reducers: {
        setPasswordReset: (state, action) => {
            state.passwordReset= action.payload;
        },
        setAddingHourFrom: (state, action) => {
            state.addingHourFrom= action.payload;
        },
        setPaymentIsSuccessful: (state, action) => {
            state.paymentIsSuccessful = action.payload;
        },
        setHourToAdd: (state, action) => {
            state.hourToAdd = action.payload;
        },
        setInsufficientHour: (state, action) => {
            state.insufficientHour = action.payload;
        },
        setPayableQuantity: (state, action) => {
            state.payableQuantity = action.payload;
        },
        setIsNewPaymentMethod: (state, action) => {
            state.isNewPaymentMethod = action.payload;
        },
        setCurrentPaymentMethod: (state, action) => {
            state.currentPaymentMethod = action.payload;
        },
        setSettingEnableAutoPay: (state,action) =>{
            state.settingEnableAutoPay = action.payload;
        }
    }
})

export const {
    setPasswordReset,
    setAddingHourFrom,
    setPaymentIsSuccessful,
    setHourToAdd,
    setInsufficientHour,
    setPayableQuantity,
    setIsNewPaymentMethod,
    setCurrentPaymentMethod,
    setSettingEnableAutoPay
} = accountSlice.actions;

export default accountSlice.reducer;