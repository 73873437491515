/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "react-query";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Project } from "../../../pages/project/core/_models";
import { ProjectStatus } from "../../projects/Projects";
import { getProjectsStats } from "../core/_requests";
import { useState } from "react";
import { getProjectTypes, getDashboardProjects } from "../../../pages/project/core/_requests";

type Props = {
  className?: string;
};

type ProjectLineProps = {
  project: Project;
};

type ProjectProgressionProps = {
  progression: number;
};


const ProjectProgression = ({ progression }: ProjectProgressionProps) => {
  return (
    <>
      <span
        className={`badge ${
          progression < 40
            ? "badge-light-danger"
            : progression < 80
            ? "badge-light-warning"
            : "badge-light-success"
        } fs-7 text-center`}
      >
        {(Math.min(progression, 100)).toFixed(2)}%
      </span>
    </>
  );
};

const ProjectLine = ({ project }: ProjectLineProps) => {
  return (
    <tr key={project.id} >
      <td className="fs-7 ">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-3">
            <img src={project.logo} alt={project.title} />
          </div>
          <Link
            to={`/projects/${project.id}`}
            className="text-gray-800 fw-bold text-hover-primary mr-0 fs-7 min-w-250px "
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '300px',
            }}
          >
            {project.title}
          </Link>  
        </div>
      </td>

      <td className="pe-0 fs-7 text-start ">
        <span className="text-gray-600 fw-bold ">
          {project?.type?.name}
        </span>
      </td>

      <td className="pe-0 fs-7 text-center ">
        <span className="text-gray-600 fw-bold ">
          {(project.budget_usage === 'time_based' && project.time_based) ? project.time_based.amount : project.budget_amount}
        </span>
      </td>

      <td className="pe-0 fs-7 text-center">
        <span className="text-gray-600 fw-bold">
          {(project.budget_usage === 'time_based' && project.time_based) ? (project.time_based.used_budget).toFixed(2) : (project.used_budget).toFixed(2)}
        </span>
      </td>

      <td className="pe-0 fs-7 text-center">
        <span className="text-gray-600 fw-bold">
          {project.budget_usage === 'quote' ? (project.saved_budget).toFixed(2) : '-'}
        </span>
      </td>
      
      <td className="pe-0 fs-7 text-center">
        {project.budget_usage === 'quote' ? (
          <ProjectProgression progression={project.progress}  />
        ) : '-'}
      </td>

      <td className="pe-0 fs-7 text-center">
        <span className="text-gray-600 fw-bold">
        {(project?.deadline ?? '').slice(0, 10)}
        </span>
      </td>

      <td className="fs-7 text-center">
        <ProjectStatus status={project.status} />
      </td>
      <td className="text-end">
        <Link
          to={`/projects/${project.id}`}
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
        >
          <i className="ki-duotone ki-black-right fs-2 text-gray-500"></i>
        </Link>
      </td>
    </tr>
  );
};

const ProjectsStates: React.FC<Props> = ({ className }) => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") || "all";
  const type = searchParams.get("type") || "all";
  const currentPage = searchParams.get("page") || 1;
  const[selectedStatus,setSelectedStatus] = useState<string>('All Status');
  const[selectedType,setSelectedType] = useState<string>('All');
  const[selectedTypeTitle,setSelectedTypeTitle] = useState<string>('All Type');
  const {data, isLoading} = useQuery(["/dashboard", currentPage,status,type], () =>
    getDashboardProjects(currentPage, status, type)
  );
  const{data:projectTypes}=useQuery('/project/types',
    ()=>getProjectTypes(),    
  );

  const handlePageChange = (page) => {
    searchParams.set("page", page);
    navigate(`?${searchParams.toString()}`);
  };

  const getFilteredProjects = (projects) => {
    return projects?.filter((project) => {
      if (!(selectedType === "All" || !selectedType)) {
        return project.type.name === selectedType;
      }
      return project;
    });
  };
  const handleTypeChange = (event) => {
    const selectedTypeID = event.target.value;
    const selectedTypeTitle = event.target.options[event.target.selectedIndex].text;
    setSelectedTypeTitle(selectedTypeTitle);
    searchParams.delete("status");
    setSelectedStatus('All Status');
    searchParams.set("type", selectedTypeID);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };
  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setSelectedStatus(selectedStatus);
    searchParams.delete("type");
    setSelectedTypeTitle("All Type");
    searchParams.set("status", selectedStatus);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  const filteredProjectsCount  = selectedType === "All" ? data?.meta?.total : getFilteredProjects(data?.data || []).length;

  return (
    <div className={`card ${className} h-520px overflow-auto`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{selectedType} Projects</span>
          <span className="text-muted mt-1 fw-semibold fs-7 text-center">
            {filteredProjectsCount} Projects
          </span>
        </h3>
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="All Projects"
        >
          <div className="d-flex flex-wrap my-2">
          <div className="me-4">
          <select
              name="type"
              data-control="select2"
              data-hide-search="true"
              className="form-select form-select-sm bg-primary w-125px "
              value={selectedTypeTitle || "All"}
              onChange={handleTypeChange}
          >
            <option value="all" >{selectedTypeTitle}</option>
              {projectTypes &&
              projectTypes.map((projectType)=>{
                return(
                <optgroup label={projectType.name} key={projectType.id} className="bg-light">
                    {projectType.subTypes &&
                    projectType.subTypes.map((subType)=>{
                      return(
                        <option value={subType.id} key={subType.id}>{subType.name}</option>
                      )
                    })
                    }              
                </optgroup>
                )
              })
              }
          </select>
          </div>
          <div className="me-4">
          <select
              name="type"
              data-control="select2"
              data-hide-search="true"
              className="form-select form-select-sm bg-primary w-125px "
              value={selectedTypeTitle || "All"}
              onChange={handleStatusChange}
          >
            <option value="all">{selectedStatus}</option>
            <option value="pending">Pending</option>
            <option value="approved">Approve needed</option>
            <option value="in_progress">In progress</option>
            <option value="completed">Completed</option>
            <option value="overdue">Payment Due</option>
            <option value="canceled">Canceled</option>
          </select>
          </div>
        </div>
        </div>
      </div>
      {/* begin::Body */}
      <div className="card-body py-3">
        <div className="tab-content">
          {/* begin::Tap pane */}
          <div
            className="tab-pane fade show active"
            id="kt_table_widget_7_tab_1"
          >
            {/* begin::Table container */}
            <div className="table-responsive border-bottom-0">
              {/* begin::Table */}
              <table className="table table-sm align-middle gs-0 gy-3 m-0 ">
                {/* begin::Table head */}
                <thead>
                  <tr className="fs-sm fw-bold text-gray-300 border-bottom-0">
                    <th className="p-1 pb-2 min-w-170px text-start fs-7 ">ITEM</th>
                    <th className="p-1 pb-2 min-w-170px text-start fs-7 ">TYPE</th>
                    <th className="p-1 pb-2 min-w-40px fs-7 text-center">BUDGET</th>
                    <th className="p-1 pb-2  min-w-40px fs-7 text-center">
                      USED <br/> HOURS
                    </th>
                    <th className="p-1 pb-2 min-w-40px fs-7 text-center">
                      SAVED <br/> HOURS
                    </th>
                    <th className="p-1 pb-2 min-w-70px fs-7 text-center">PROGRESS</th>
                    <th className="p-1 pb-2 min-w-80px fs-7 text-center">DUE DATE</th>
                    <th className="p-1 pb-2 min-w-70px fs-7 text-center">
                      STATUS
                    </th>
                    <th className="p-1 pb-2 min-w-50px fs-7 text-center">VIEW</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                {isLoading ? (
                  <div className="text-center my-10" id="files-loading">
                  <span className="spinner-border align-middle"></span>
                </div>
                ): !getFilteredProjects(data?.data).length ? (
                  <div></div>
                ): (
                <tbody >
                 {data &&
                  Array.isArray(data.data) &&
                  getFilteredProjects(data.data)?.map((row) => (
                    <ProjectLine project={row} key={row.id} />
                ))}
                </tbody>
                )
                }
                {/* end::Table body */}
              </table >
              <div className="d-flex flex-stack flex-wrap ">
              <div className="d-flex justify-content-between flex-fill d-sm-none">
                <ul className="pagination">
                  <li className="page-item disabled">
                    <span className="page-link">
                      <i className="previous"></i>
                    </span>
                  </li>

                  <li className="page-item next">
                    <a
                      className="page-link"
                      href="https://spreadsheet-backend.definance.one/projects?page=2"
                      rel="next"
                    >
                      <i className="next"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="d-none flex-sm-fill d-sm-flex align-items-sm-center justify-content-sm-between">
                {filteredProjectsCount === 0 ?
                <div className="fs-6 fw-semibold text-gray-700">
                  Showing 0 results
                </div>
                :
                <div className="fs-6 fw-semibold text-gray-700">
                Showing {" "}
                <span className="fw-semibold">
                  {(parseInt(currentPage.toString()) - 1) * 5 + 1}
                </span>
                {" "}to {" "} 
                <span className="fw-semibold">
                  {Math.min(filteredProjectsCount, parseInt(currentPage.toString()) * 5)}
                </span>
                {" "}of {" "}
                <span className="fw-semibold"> {filteredProjectsCount} </span>
                results
                </div>
                }

              <div>
                <ul className="pagination">
                  <li
                    className={`page-item previous ${
                      currentPage === "1" ? "disabled" : ""
                    }`}
                  >
                    <button
                      type="button"
                      className={`page-item previous btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-1`}
                      disabled={currentPage === "1"}
                      onClick={() => {
                        if (currentPage !== "1") {
                          handlePageChange(`${parseInt(currentPage.toString()) - 1}`);
                        }
                      }}
                    >
                      <span className="page-link">
                        <i className="previous"></i>
                      </span>
                    </button>
                  </li>
                  {Array.from({ length: Math.ceil(filteredProjectsCount / 5) }).map(
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item cursor-pointer ${
                          currentPage === `${index + 1}` ? "active" : ""
                        }`}
                        aria-current="page"
                        onClick={() => handlePageChange(`${index + 1}`)}
                      >
                        <span className="page-link">{index + 1}</span>
                      </li>
                    )
                  )}

                  <li
                    className={`page-item next ${
                      currentPage === `${Math.ceil(filteredProjectsCount / 5)}` ? "disabled" : ""
                    }`}
                  >
                    <button
                      type="button"
                      className={`page-item previous btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-1`}
                      disabled={
                        currentPage === `${Math.ceil(filteredProjectsCount / 5)}`
                      }
                      onClick={() => {
                        if (
                          currentPage !== `${Math.ceil(filteredProjectsCount / 5)}`
                        ) {
                          handlePageChange(`${parseInt(currentPage.toString()) + 1}`);
                        }
                      }}
                    >
                      <span className="page-link">
                        <i className="next"></i>
                      </span>
                    </button>
                  </li>
                </ul>
              </div>

              </div>
            </div>
            </div>
            {/* end::Table */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsStates;
