import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {updatePaymentMethod, confirmPayment} from "../../core/_requests";
import {useQueryClient} from "react-query";
import { setIsNewPaymentMethod, setPaymentIsSuccessful } from "../../../../store/AccountSlice";
import {useDispatch, useSelector} from "react-redux";
import { authorizeAutomaticPayment } from '../../../project/core/_requests';
import { useParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type PaymentFormProps = {
    setClientSecret: Dispatch<SetStateAction<string | undefined>>;
    setVisible: Dispatch<SetStateAction<boolean>>;
};

export default ({ setClientSecret, setVisible }: PaymentFormProps) => {
    const dispatch= useDispatch();
    const queryClient = useQueryClient();
    const stripe = useStripe();
    const elements = useElements();
    const [hasError, setHasError] = useState(false);
    const [termsNotAccepted, setTermNotsAccepted] = useState(false);
    const budgetUsage = useSelector((state:any)=>state.project.budgetUsage);
    const projectId= useSelector((state:any)=>state.project.projectID);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const addingHourFrom = useSelector((state: any) => state.account.addingHourFrom);

    const handleCancel = () => {
        setClientSecret(undefined);
        setVisible(false);
    }

    const consentCheckBox = useRef<HTMLInputElement | null>(null);

    const handleConsentChange = ()=> {
        if (consentCheckBox) {
            setTermNotsAccepted(!consentCheckBox.current?.checked);
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        /*Check if consent checkbox ix checked for time based project.This is mandatory */

        const consentChecked = consentCheckBox.current?.checked;

        if(budgetUsage==="time_based" && !consentChecked){
            setTermNotsAccepted(true);
            return;
        }

        /* Check stripe connection and confirm payment */

        if (!stripe || !elements) return;

        setIsProcessing(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/hours/paid`,
                save_payment_method:termsNotAccepted===true ? false : true
            },
            redirect: 'if_required'
        });

        if (error) {
            setHasError(true);
        }

        if (paymentIntent && paymentIntent.status === 'succeeded') {
            confirmPayment(paymentIntent.id).then(() => {
                queryClient.invalidateQueries({ queryKey: ['/wallet/balance'] })
            });
            if(consentChecked===true){
                const paymentMethodID = paymentIntent.payment_method?.toString();
                if(paymentMethodID!=null)
                updatePaymentMethod(paymentMethodID).then((response)=>{
                    dispatch(setIsNewPaymentMethod(true));
                })
                if(projectId){
                    authorizeAutomaticPayment(+projectId)
                }
            }
            setClientSecret(undefined);
            setVisible(false);
            dispatch(setPaymentIsSuccessful(true));
        }

        setIsProcessing(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {hasError && (
                <div className="alert alert-danger p-2 mb-5">
                    <div className="d-flex flex-column">
                        <h5 className="mb-1">Error</h5>
                        <span>An error has occurred</span>
                    </div>
                </div>
            )}
            {/* <p>By checking the checkbox below, you agree that Definance will automatically charge this payment method for any future payments needed for this project.</p> */}
            
            {(addingHourFrom == 'project_form'|| addingHourFrom == 'approve_project' || addingHourFrom == 'pay_project') &&
            <>
            <div className="form-check mt-6">    
            <input className="form-check-input " type="checkbox" value="" ref={consentCheckBox} id="save-payment-method" name="consentCheckbox" onChange={handleConsentChange} role="button" required/>
                <label className="form-check-label text-dark fw-bold fs-5 required" htmlFor="save-payment-method">
                    I agree to automatic billing for this project.
                      <OverlayTrigger
                            placement="top"                      
                            overlay={
                                 <Tooltip id="tooltip-with-html" className="text-dark fw-bold">
                                    <span className=''>
                                        This allows DeFinance to automatically bill you for services and avoid interuptions in the recurring work that we do. We will not bill you for more than agreed to in the projects service terms. If you do not want automatic billing, please message us to discuss a one time project option that is not recurring in nature.
                                    </span>
                            </Tooltip>
                            }
                        >
                            <span className='text-primary' >{" "}More Details</span>
                        </OverlayTrigger>
                </label>
            </div>
            {termsNotAccepted &&
                  <span  className="text-danger">As a recurring project, the agreement above is required to continue.</span>
            }
            </>
            }
            <div className='d-flex flex-stack justify-content-between pt-10'>
                <button type='button' className='btn btn-light-primary' onClick={handleCancel}>
                    Cancel
                </button>
                <button type='submit' className='btn btn-primary' disabled={isProcessing}>
                    {isProcessing ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (
                        <span className='indicator-label'>Pay</span>
                    )}
                </button>
            </div>
        </form>
    );
}