import { createSlice } from '@reduxjs/toolkit';

export const projectSlice= createSlice({
    name: 'project',
    initialState: {
        subTypes: [],
        autoApproveProject: false,
        autoPayProject: false,
        budgetUsage:'',
        payAutomated:false,
        projectID:'',
        pmFromProjectApprove: false,
    },
    reducers: {
        setSubTypes: (state, action) => {
            state.subTypes = action.payload;
        },
        setAutoApproveProject: (state, action) => {
            state.autoApproveProject = action.payload;
        },
        setAutoPayProject: (state, action) => {
            state.autoPayProject = action.payload;
        },
        setBudgetUsage: (state,action)=>{
            state.budgetUsage =action.payload;
        },
        setPayAutomated: (state,action)=>{
            state.payAutomated =action.payload;
        },
        setProjectID: (state,action)=>{
            state.projectID =action.payload;
        },
        setPmFromProjectApprove: (state,action)=>{
            state.pmFromProjectApprove = action.payload;
        }
    }
})

export const {
    setSubTypes,
    setAutoApproveProject,
    setAutoPayProject,
    setBudgetUsage,
    setPayAutomated,
    setProjectID,
    setPmFromProjectApprove,
} = projectSlice.actions;

export default projectSlice.reducer;